






















































































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';
import Pusher from 'pusher-js';
import PusherAdmin from 'pusher';

@Component({
  components: {
    MyNav
  }
})
export default class Home extends Vue {
  public showDialog = false;
  public list: any = null
  public loading = false;
  public step = 0;
  public liveID = ''
  public items = [];
  public errorMsg  = '';
  public chat = ''
  public videoID = ''
  public desc = '';
  public btn = '';
  public language = 'pt';
  public email = '';
  public question = '';
  public error = false;
  public edit: any = undefined;
  public boxMsg = '';
  public user = '';
  public extras = []
  public obj: any = this.reset(); 

  public randomClass(){
     return 'emoji' + Math.floor(Math.random()*(3-1+1)+1)
  }

  public reset() {
    return {    
      _id: null,
      name: '',
      slug: '',
      client: this.$route.params.code,
      bgLogin : '',
      bgLive : '',
      typeLive : null,
      hasPrize: false,
      hasQuestion: false,
      question: '',
      typeValidation : null,
      typeIdioma : null,
      linkExternal : null,
      addInputs : [],
      extraInputs : null,
      validationStrings : null,
      languages : {
        pt:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        en:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        es:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        }
      },
      addInput: null,
      chatLink: null
    }
  }

  public pusher = new Pusher('c5e223f19015c40d4a2c', {
      cluster: 'us2'
  });
    

  public async created(){
    document.title = `Reactions auren-aurenday2024`;  
    console.log(`channel-auren-aurenday2024`)  
    var channel = this.pusher.subscribe(`channel-auren-aurenday2024`);
    channel.bind('emoji', function(data: any) {
      //@ts-ignore
      this.items.push({msg: data.message, class: this.randomClass()})
    }.bind(this));
    //await this.atualiza();      
  }


}
